require('./bootstrap');
require('iframe-resizer/js/iframeResizer.contentWindow');

$(document).ready(function () {

  let expires = (new Date(Date.now()+ 86400*1000)).toUTCString();

  document.cookie = "4057753c-a3ff-4b26-9184-e2c6214419c3=4057753c-a3ff-4b26-9184-e2c6214419c3; expires=" + expires + "; path=/; SameSite=None; Secure;";

  setTimeout(function(){

    if (document.cookie.indexOf("4057753c-a3ff-4b26-9184-e2c6214419c3") > -1) {
      $('body').fadeIn('fast');
    } else {
      $('body').css('background','#FFFFFF').find('#header, #footer, main, section').remove().end().find('#thirdpartycookies').fadeIn();
    }

  }, 500);

  if ('parentIFrame' in window) {
    parentIFrame.scrollTo(0,0);
  }

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    cache: false,
  });

  // --- debug ----------------------------------------------------------------------
  function afterAjaxCall() {
    $.ajax({
      url: '/debug',
      method: 'post',
      dataType: "html",
      cache: false,
      data: {
        time: Date.now(),
      },
      success: function (response) {
        $('#debug').html(response);
      }
    });
  }

  if($('#debug').length > 0){
    let ajaxCallScheduled = false;

    $(document).ajaxComplete(function(event, jqxhr, settings) {
      if (settings.url !== '/debug') {
        if (!ajaxCallScheduled) {
          ajaxCallScheduled = true;
          setTimeout(function() {
            afterAjaxCall();
            ajaxCallScheduled = false; // Reset the flag after 2 seconds
          }, 500); // 2000ms delay
        }
      }
    });
  }
  // -- debug end -------------------------------------------------------------------

  $('#roof').on('click', 'label', function () {

    let img = $(this).find('img');
    let radio = $(this).next('input:radio').val();
    let remove = [
      '#manufacturer',
      '#application',
      '#area',
      '#connection',
      '#size',
      '#button',
    ];

    $(this).next('input:radio').prop("checked", true).change();
    $(this).find('img').addClass('active').closest('section').find('img').not(img).removeClass('active');

    $.ajax({
      url: '/form/roof',
      method: 'post',
      dataType: 'json',
      data: {
        roof: radio,
        time: Date.now(),
      },
      success: function (response) {
        // --> Bitumen > Application --------------------------------------------------------------------------------- >
        if (response.roof === '1') {

          $.ajax({
            url: '/tpl/application',
            method: 'post',
            dataType: "html",
            cache: false,
            data: {
              time: Date.now(),
            },
            success: function (response) {

              $(remove.join(',')).hide().html('');
              $('#application').append(response).css({'display': 'flex'}).fadeIn('fast');

              setTimeout(
                function () {
                  let offset = $('#application').height();
                  window.scrollBy({
                    top: offset,
                    behavior: 'smooth',
                  });
                }, 400);
            }
          });
        }
        // --> Plastic > Manufacturer -------------------------------------------------------------------------------- >
        if (response.roof === '2') {

          $.ajax({
            url: '/tpl/manufacturer',
            method: 'post',
            dataType: "html",
            cache: false,
            data: {
              time: Date.now(),
            },
            success: function (response) {

              $(remove.join(',')).hide().html('');
              $('#manufacturer').hide().append(response).css({'display': 'flex'}).fadeIn();

              setTimeout(
                function () {
                  let offset = $('#manufacturer').height();
                  window.scrollBy({
                    top: offset,
                    behavior: 'smooth',
                  });
                }, 400);
            }
          });
        }
      }
    });
  });

  $('#manufacturer').on('change', 'select[name=manufacturer]', function () {

    let id = $(this).val();
    let membrane = $('select[name=membrane]');
    let remove = [
      '#application',
      '#area',
      '#connection',
      '#size',
      '#button',
    ];

    $.ajax({
      url: '/form/roof',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        roof: 2,
        time: Date.now(),
      },
      success: function () {
        $(remove.join(',')).hide().html('');
      }
    });

    $(membrane).val(0);

    if (id > 0) {
      $(membrane).prop("disabled", false).find('option').prop('hidden', true).end().find('option[data-id="' + id + '"]').prop('hidden', false);
    } else {
      $(membrane).prop("disabled", true).find('option').prop('hidden', true).end().val(0);
    }
  });

  $('#manufacturer').on('change', 'select[name=membrane]', function () {

    let manufacturer = $('#manufacturer select[name=manufacturer]').val();
    let membrane = $(this).val();
    let remove = [
      '#application',
      '#area',
      '#connection',
      '#size',
      '#button',
    ];

    if (membrane > 0 && manufacturer > 0) {

      $.ajax({
        url: '/form/manufacturer',
        method: 'post',
        dataType: 'json',
        cache: false,
        data: {
          manufacturer: $('select[name=manufacturer]').val(),
          membrane: $('select[name=membrane]').val(),
          time: Date.now(),
        },
        success: function () {

          $.ajax({
            url: '/tpl/application',
            method: 'post',
            dataType: 'html',
            cache: false,
            data: {
              time: Date.now(),
            },
            success: function (response) {

              $(remove.join(',')).hide().html('');
              $('#application').append(response).css({'display': 'flex'}).fadeIn();

              setTimeout(
                function () {
                  let offset = $('#application').height();
                  window.scrollBy({
                    top: offset,
                    behavior: 'smooth',
                  });
                }, 400);
            }
          });
        }
      });
    }
  });

  // select application a show/hide attached product groups
  $('#application').on('click', 'label', function () {

    let img = $(this).find('img');
    let remove = [
      '#area',
      '#connection',
      '#size',
      '#button',
    ];

    $(this).next('input:radio').prop("checked", true).change();
    $(this).find('img').addClass('active').closest('section').find('img').not(img).removeClass('active');

    $.ajax({
      url: '/form/application',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        application: $(this).next('input:radio').val(),
        time: Date.now(),
      },
      success: function () {

        $.ajax({
          url: '/tpl/area',
          method: 'post',
          dataType: 'html',
          cache: false,
          data: {
            time: Date.now(),
          },
          success: function (response) {

            $(remove.join(',')).hide().html('');
            $('#area').append(response).css({'display': 'flex'}).fadeIn();

            setTimeout(
              function () {
                let offset = $('#area').height();
                window.scrollBy({
                  top: offset,
                  behavior: 'smooth',
                });
              }, 400);
          }
        });
      }
    });
  });

  // select application a show/hide attached product groups
  $('#area').on('click', 'label', function () {

    let img = $(this).find('img');
    let area = $(this).next('input:radio').val();
    let connection = $(this).data('connection');
    let sizes = $(this).data('size');
    let remove = [
      '#connection',
      '#size',
      '#button',
    ];

    $(this).next('input:radio').prop("checked", true).change();
    $(this).find('img').addClass('active').closest('section').find('img').not(img).removeClass('active');

    $.ajax({
      url: '/form/area',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        area: area,
        connection: connection,
        time: Date.now(),
      },
      success: function (response) {

        // connection selector ----------------------------------------------------------------------------- >
        if (response.connection?.length > 0){

          $.ajax({
            url: '/tpl/connection',
            method: 'post',
            dataType: 'html',
            cache: false,
            data: {
              connection: connection,
              time: Date.now(),
            },
            success: function (response) {

              $(remove.join(',')).hide().html('');
              $('#connection').append(response).css({'display': 'flex'}).fadeIn();

              setTimeout(
                function () {
                  let offset = $('#area').height();
                  window.scrollBy({
                    top: offset,
                    behavior: 'smooth',
                  });
                }, 400);
            }
          });

        } else {

          $.ajax({
            url: '/tpl/size',
            method: 'post',
            dataType: 'html',
            cache: false,
            data: {
              sizes: sizes,
              time: Date.now(),
            },
            success: function (response) {

              $(remove.join(',')).hide().html('');
              $('#size').append(response).css({'display': 'flex'}).fadeIn();

              setTimeout(
                function () {
                  let offset = $('#area').height();
                  window.scrollBy({
                    top: offset,
                    behavior: 'smooth',
                  });
                }, 400);
            }
          });

        }
      }
    });
  });

  $('#connection').on('click', 'label', function () {

    let img = $(this).find('img');
    let sizes = $('#area').find('img.active').closest('label').data('size');
    let remove = [
      '#size',
      '#button',
    ];

    $(this).next('input:radio').prop("checked", true).change();
    $(this).find('img').addClass('active').closest('section').find('img').not(img).removeClass('active');

    $.ajax({
      url: '/form/connection',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        connection: $(this).next('input:radio').val(),
      },
      success: function () {

        $.ajax({
          url: '/tpl/size',
          method: 'post',
          dataType: 'html',
          cache: false,
          data: {
            sizes: sizes,
            time: Date.now(),
          },
          success: function (response) {

            $(remove.join(',')).hide().html('');
            $('#size').append(response).css({'display': 'flex'}).fadeIn();

            setTimeout(
              function () {
                let offset = $('#area').height();
                window.scrollBy({
                  top: offset,
                  behavior: 'smooth',
                });
              }, 400);
          }
        });
      }
    });
  });

  $('#size').on('click', 'label', function () {

    let div = $(this).find('div');
    let radio = $(this).next('input:radio').val();
    let remove = [
      '#button',
    ];

    $(this).next('input:radio').prop("checked", true).change();
    $(this).find('div').addClass('btn-active').closest('section').find('label > div').not(div).removeClass('btn-active');

    $.ajax({
      url: '/form/size',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        size: radio,
        time: Date.now(),
      },
      success: function () {

        $.ajax({
          url: '/tpl/button',
          method: 'post',
          dataType: 'html',
          cache: false,
          data: {
            time: Date.now(),
          },
          success: function (response) {

            $(remove.join(',')).hide().html('');
            $('#button').append(response).css({'display': 'flex'}).fadeIn();

            setTimeout(
              function () {
                let offset = $('#button').height();
                window.scrollBy({
                  top: offset,
                  behavior: 'smooth',
                });
              }, 400);
          }
        });
      }
    });
  });

  // ----------------------------------------------------------------------------------------------------- result lis ---->
  $('#check-all').on('click', function () {

    let checkboxes = $('#result-list').find('input[type=checkbox]');

    if ($(checkboxes).not(':checked').length > 0) {
      $(checkboxes).prop('checked', true);
    } else {
      $(checkboxes).prop('checked', false);
    }
  });

  $('#result-list').find('a').on('click', function () {

    $.ajax({
      url: '/result/modal',
      method: 'post',
      dataType: 'html',
      cache: false,
      data: {
        id: $(this).data('id'),
        time: Date.now(),
      },
      success: function (response) {
        $('.modal-content').html(response).modal('show');
        $('#result-details').on('hide.bs.modal', function () {
          $('.modal-backdrop').remove();
        });
      }
    });
  });

  // ----------------------------------------------------------------------------------------------------- order list ---->

  $('.quantity-plus, .quantity-minus').on('click', function () {

    event.preventDefault();

    let id = $(this).data('id');
    let input = $(this).closest('.input-group').find('input');
    let quantity = parseInt($(input).val());

    if ($(this).hasClass('quantity-plus')) {
      if (quantity < 100) {
        $(input).val(quantity + 1);
      }
    }
    if ($(this).hasClass('quantity-minus')) {
      if (quantity >= 2) {
        $(input).val(quantity - 1);
      }
    }

    $.ajax({
      url: '/basket/quantity',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        id: id,
        quantity: $(input).val(),
        time: Date.now(),
      },
      success: function (response) {
        $(input).val(response.quantity);
      }
    });

  });

  $('.remove-item').on('click', function () {

    event.preventDefault();

    let id = $(this).data('id');

    $.ajax({
      url: '/basket/remove',
      method: 'post',
      dataType: 'json',
      cache: false,
      data: {
        id: id,
        time: Date.now(),
      },
      success: function (response) {

        let id = response.id;
        $('#' + id).fadeOut(500, function () {
          $('#' + id).remove();
        });

        $.ajax({
          url: '/basket/count',
          method: 'post',
          dataType: 'json',
          cache: false,
          data: {
            time: Date.now(),
          },
          success: function (response) {
            if (parseInt(response.count) > 0) {
              $('.basket-quantity').text(response.count);
            } else {
              $('.basket-quantity').closest('li').fadeOut('fast', function () {
                $('.basket-quantity').closest('li').remove();
              });
              $('#order-list').html('<h5 class="col-12">Ihre Produktauswahl ist leer.</h5>');
              $('#create-pdf').fadeOut('fast');
            }

          }
        });
      }
    });

  });

});
